import { Link } from "gatsby";
import React from "react";
import { css } from "@emotion/react";
import { Div } from "@components/Markup";
import { Text } from "@components/Typography";

const BlogPostCategories = ({ fragen, news, downloads, dinge }) => {
  const cats = [
    { ck: "113-fragen", cv: fragen, cd: "113 Fragen" },
    { ck: "news", cv: news, cd: "News" },
    { ck: "downloads", cv: downloads, cd: "Downloads" },
    { ck: "250-dinge", cv: dinge, cd: "250 Dinge" },
  ];
  return (
    <Div flex mb="xs">
      {cats.map(({ ck, cv, cd }, index) => {
        if (cv === true) {
          return (
            <Link
              key={`category--${index}`}
              to={`/blog/${ck}`}
              css={css`
                margin-right: 16px;
                border-bottom: 1px solid black;
                text-transform: capitalize;
              `}
            >
              <Text textStyle="caption">{cd}</Text>
            </Link>
          );
        }
      })}
    </Div>
  );
};

export default BlogPostCategories;
