import React from "react";
import { Link } from "gatsby";
import { css } from "@emotion/react";
import Image from "gatsby-image";
import { Text } from "./Typography";

const thumbnailCss = `display: block;
        transition: 0.15s all;
        margin-bottom: 16px;
        padding-top: 56.25%;
        position: relative;

        @media (min-width: 768px) {
          margin-bottom: 16px;
        }
        `;

const ThumbnailImage = ({ featuredImage }) => {
  return (
    <Image
      fluid={featuredImage ? featuredImage.fluid : null}
      css={css`
        position: absolute !important;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
      `}
    />
  );
};

export const PostThumbnailLink = ({ featuredImage, url }) => {
  return (
    <Link
      to={url}
      css={css`
        ${thumbnailCss}
        &:hover {
          opacity: 0.75;
          cursor: pointer;
        }
      `}
    >
      <ThumbnailImage featuredImage={featuredImage} />
    </Link>
  );
};

export const PostThumbnailDownload = ({
  featuredImage,
  document,
  documentName,
}) => {
  return (
    <a
      css={css`
        ${thumbnailCss}
        position: relative;
        transition: 0.15s all;
        &:hover {
          opacity: 0.75;
        }
      `}
      href={document.url}
      download
      target="_blank"
      rel="noreferrer"
    >
      <ThumbnailImage featuredImage={featuredImage} />
      <div
        css={css`
          position: absolute;
          background-color: white;
          width: 56.3%;
          padding: 24px 16px 24px 16px;
          bottom: 0;
          right: 0;
          border: 2px solid black;
          background-color: white;
          text-align: center;
          //color: white;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          transform: rotate(-90deg) translate3d(100%, 0, 0);
          transform-origin: bottom right;
          h3 {
            font-weight: bold !important;
            font-family: "noah-heavy";
            font-size: 18px;
            line-height: 1.1em;
          }
          img {
            margin-right: 16px;
            margin-top: 4px;
            display: none;
          }
        `}
      >
        <Text tag="h3" bold>
          {documentName}
        </Text>
      </div>
    </a>
  );
};

export const PostThumbnail = ({ featuredImage }) => {
  return (
    <div
      css={css`
        ${thumbnailCss}
      `}
    >
      <ThumbnailImage featuredImage={featuredImage} />
    </div>
  );
};
