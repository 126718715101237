import { Link } from "gatsby";
import React, { useContext, useState, useEffect } from "react";
import { css } from "@emotion/react";
import { Div, Container } from "@components/Markup";
import PaginationNav from "@components/PaginationNav";
import { ResizeContext } from "@providers/ResizeProvider";

const PaginationItem = ({ url, itemNumber, isCurrent }) => {
  return (
    <Link
      css={css`
        height: 40px;
        width: 40px;
        min-width: 32px;
        border: 2px solid #333;
        margin: 0px 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "lucida-sans-bold";
        transition: 0.15s all;
        background-color: ${isCurrent ? "#333" : "white"};
        color: ${isCurrent ? "white!important" : "#333"};
        font-size: 14px;
        text-transform: uppercase;

        @media (min-width: 768px) {
          height: 48px;
          width: 48px;
        }

        &:hover {
          background-color: #333;
          color: white;
        }
      `}
      to={url}
    >
      {itemNumber}
    </Link>
  );
};

const getItems = (currentPage, pageCount, MAX_ITEM_COUNT) => {
  let items = [];
  const cp = Number(currentPage);
  let startingItem = 0;
  let endItem = 0;
  if (
    cp > (MAX_ITEM_COUNT - 1) / 2 &&
    cp < pageCount - (MAX_ITEM_COUNT - 1) / 2
  ) {
    startingItem = cp - (MAX_ITEM_COUNT - 1) / 2;
    endItem = cp + (MAX_ITEM_COUNT - 1) / 2;
  }

  if (
    cp > (MAX_ITEM_COUNT - 1) / 2 &&
    cp >= pageCount - (MAX_ITEM_COUNT - 1) / 2
  ) {
    startingItem = pageCount - MAX_ITEM_COUNT + 1;
    endItem = pageCount;
  }

  if (
    cp <= (MAX_ITEM_COUNT - 1) / 2 &&
    cp < pageCount - (MAX_ITEM_COUNT - 1) / 2
  ) {
    startingItem = 1;
    endItem = MAX_ITEM_COUNT;
  }

  for (let i = startingItem; i <= endItem; i += 1) {
    items.push(i);
  }

  return items;
};

const Pagination = ({ currentPage, pageCount, baseUrl }) => {
  const { mobile } = useContext(ResizeContext);
  const [items, setItems] = useState(null);

  useEffect(() => {
    if (mobile) {
      setItems([...getItems(currentPage, pageCount, 5)]);
    }

    if (!mobile) {
      setItems([...getItems(currentPage, pageCount, 9)]);
    }
  }, [mobile]);

  const cp = Number(currentPage);
  const prev = cp - 1;
  const next = cp + 1;

  return (
    <Container>
      <Div flex jc="space-between" ai="center" pt="sm" pb="md">
        <PaginationNav
          disabled={cp === 1 ? true : false}
          dir="prev"
          text="vorherige"
          url={prev === 1 ? `${baseUrl}` : `${baseUrl}/page/${String(prev)}`}
        />
        <Div
          flex
          css={css`
            position: relative;

            &:before,
            &:after {
              content: "";
              display: inline-block;
              width: 60px;
              height: 100%;
              position: absolute;
              top: 0;
              pointer-events: none;
            }

            &:before {
              background: linear-gradient(to right, white, transparent);
              left: 0;
              display: ${items && items[0] === 1 ? "none" : "inline-block"};
            }

            &:after {
              background: linear-gradient(to left, white, transparent);
              right: 0;
              display: ${items && items[items.length - 1] === pageCount
                ? "none"
                : "inline-block"};
            }
          `}
        >
          {items?.map(item => {
            return (
              <PaginationItem
                itemNumber={item}
                isCurrent={item === Number(currentPage)}
                url={
                  item === 1 ? `${baseUrl}` : `${baseUrl}/page/${String(item)}`
                }
              />
            );
          })}
        </Div>
        <PaginationNav
          disabled={cp === pageCount ? true : false}
          dir="next"
          text="nächste"
          url={`${baseUrl}/page/${String(next)}`}
        />
      </Div>
    </Container>
  );
};

export default Pagination;
