import { Link } from "gatsby";
import React from "react";
import { css } from "@emotion/react";
import { Div, Container } from "@components/Markup";
import { IconPng } from "@components/Icon";

const Nav = ({ text, dir, url, disabled }) => {
  return (
    <Div
      css={css`
        img {
          float: ${dir === "next" ? "right" : "left"};
        }
        transition: 0.15s all;
        opacity: ${disabled ? 0 : 1};
        pointer-events: ${disabled ? "none" : "all"};

        &:hover {
          opacity: 0.65;
        }
      `}
    >
      <Link to={url}>
        <span
          css={css`
            font-family: "noah-heavy";
            font-size: 16px;
            line-height: 1em;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            @media (max-width: 767px) {
              display: none;
            }
          `}
        >
          {text}
        </span>
        <IconPng name={dir} />
      </Link>
    </Div>
  );
};

export default Nav;
